
<template>
   <li>
      <div class="card">
         <div class="card-body p-2">
            <div class="row m-0 text-xxl-center align-items-center font-13">
               <div class="col-md text-start">{{ extrato.origem }}</div>
               <div class="wpx-125"><i class="far fa-calendar font-11 me-1 d-md-none"></i> {{ extrato.data }}</div>
               <div class="wpx-150 d-none d-lg-block">{{ extrato.documento }}</div>
               <div class="wpx-150" :class="extrato.dc == '+' ? 'color-theme' : 'text-red'">
                  {{ extrato.dc }} {{ parseFloat(extrato.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
               </div>
            </div>
         </div>
      </div>
   </li>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Extrato',
   props: ['extrato'],
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
   }
}

</script>

<style scoped>

.card {
   margin-bottom: 4px;
   border: 1px solid #d6dce1;
   border-radius: 4px;
}

.card .card-body {
   padding: 8px 12px;
}

button {
   padding: 4px 12px !important;
}

</style>