
<template>
   <li>
      <div class="card">
         <div class="card-body p-2" :class="boleto.atraso ? 'text-red' : ''">
            <div class="row m-0 text-xxl-center align-items-center font-13">
               <div class="wpx-xxl-125 px-1">
                  <span class="title-responsive">
                     {{ boleto.dataVencimento == null ? '-' : String(boleto.dataVencimento.dayOfMonth).padStart(2, '0') +'/'+ String(boleto.dataVencimento.monthValue).padStart(2, '0') +'/'+ boleto.dataVencimento.year }}
                  </span>
               </div>
               <div class="col-6 wpx-xxl-125 px-1">
                  <span class="desc-responsive"><span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Fatura: </span>{{ boleto.fatura }}</span>
               </div>
               <div class="col-6 wpx-xxl-125 px-1">
                  <span class="desc-responsive"><span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Parcela: </span>{{ boleto.parcela == null ? '-' : boleto.parcela }}</span>
               </div>
               <div class="col-6 wpx-xxl-125 px-1">
                  <span class="desc-responsive"><span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Origem: </span>{{ boleto.origem }}</span>
               </div>
               <div class="col-6 wpx-xxl-135 px-1">
                  <span class="desc-responsive">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Valor: </span>
                     R$ {{ boleto.valor == null ? '-' : parseFloat(boleto.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col px-1">
                  <span class="desc-responsive"><span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Nosso número: </span>{{ boleto.nossoNumero }}</span>
               </div>
               <div class="col-6 wpx-xxl-125 px-1">
                  <span class="desc-responsive">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Emissão: </span>
                     {{ boleto.dataEmissao == null ? '-' : String(boleto.dataEmissao.dayOfMonth).padStart(2, '0') +'/'+ String(boleto.dataEmissao.monthValue).padStart(2, '0') +'/'+ boleto.dataEmissao.year }}
                  </span>
               </div>
               <div class="wpx-xxl-125 px-1 text-xxl-end mt-2 mt-xxl-0">
                  <button class="btn bg-theme text-white font-13" @click="baixar" v-if="boleto.origem == 'Boleto'"><i class="far fa-download font-11 me-1"></i> Baixar</button>
               </div>
            </div>
         </div>
      </div>
   </li>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Boleto',
   props: ['boleto'],
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   methods: {
      baixar: function () {
         this.$store.dispatch('baixarArquivo', {'id': this.boleto.id, 'url': 'usuario/getPDFBoleto'})
      }
   }
}

</script>

<style scoped>

.card {
   margin-bottom: 4px;
   border: 1px solid #d6dce1;
   border-radius: 4px;
}

.card .card-body {
   padding: 8px 12px;
}

button {
   padding: 4px 12px !important;
}

</style>