
<template>
   <li>
      <div class="card">
         <div class="card-body p-2">
            <div class="row m-0 text-xxl-center align-items-center font-13">
               <div class="col-xxl px-1">
                  <span class="title-responsive">{{ nota.chave == null ? '-' : nota.chave }}</span>
               </div>
               <div class="wpx-xxl-200 px-1">
                  <span class="desc-responsive">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Transportador: </span>{{ nota.transportador == null ? '-' : nota.transportador }}
                  </span>
               </div>
               <div class="col-6 wpx-xxl-125 px-1">
                  <span class="desc-responsive"><span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Número: </span>{{ nota.numero == null ? '-' : nota.numero }}</span>
               </div>
               <div class="col-6 wpx-xxl-125 px-1">
                  <span class="desc-responsive">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Emissão: </span>
                     {{ nota.dataEmissao == null ? '-' : nota.dataEmissao.dayOfMonth.toString().padStart(2, '0') +'/'+ nota.dataEmissao.monthValue.toString().padStart(2, '0') +'/'+ nota.dataEmissao.year }}
                  </span>
               </div>
               <div class="col-6 wpx-xxl-150 px-1">
                  <span class="desc-responsive">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Valor: </span>
                     R$ {{ nota.valor == null ? '-' : parseFloat(nota.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
                  </span>
               </div>
               <div class="wpx-xxl-200 px-1 text-xxl-end mt-2 mt-xxl-0" v-if="view == 'Solicitacao'">
                  <button class="btn bg-theme text-white font-13 me-1" @click="devolucaoTotal"><i class="far fa-dolly font-11 me-1"></i> Devolução total</button>
                  <button class="btn bg-theme text-white font-13" @click="select"><i class="far fa-plus"></i></button>
               </div>
               <div class="wpx-xxl-285 px-1 text-xxl-end mt-2 mt-xxl-0" v-else>
                  <button class="btn bg-theme text-white font-13 me-1" @click="baixarBoletos" v-if="nota.pdfBoleto"><i class="far fa-download font-11 me-1"></i> Boletos</button>
                  <button class="btn bg-theme text-white font-13 me-1" @click="baixarNota"><i class="far fa-download font-11 me-1"></i> Nota</button>
                  <button class="btn bg-theme text-white font-13 me-1" @click="visualizar"><i class="far fa-clipboard font-11 me-1"></i> Visualizar</button>
               </div>
            </div>
         </div>
      </div>
   </li>
</template>

<script>

export default {
	name: 'Nota',
   props: ['nota', 'view'],
   methods: {
      select : function () {
         this.$emit('select', this.nota)
      },
      devolucaoTotal : function () {
         this.$emit('devolucaoTotal', this.nota)
      },
      baixarNota : function () {
         this.$store.dispatch('baixarArquivo', {'id': this.nota.id, 'url': 'usuario/getPDFNota'})
      },
      baixarBoletos : function () {
         this.$store.dispatch('baixarArquivo', {'id': this.nota.id, 'url': 'usuario/getBoletosNota'})
      },
      visualizar : function () {
         this.$emit('visualizar', this.nota)
      }
   }
}

</script>

<style scoped>

.card {
   margin-bottom: 4px;
   border: 1px solid #d6dce1;
   border-radius: 4px;
}

.card .card-body {
   padding: 8px 12px;
}

button {
   padding: 4px 12px !important;
}

</style>