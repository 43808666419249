
<template>
   <li>
      <div class="card">
         <div class="card-body p-2">
            <div class="row m-0 text-xxl-center align-items-center font-13">
               <div class="col-xxl px-1">
                  <span class="title-responsive" v-if="pedido.idPedido == null && pedido.id == null">Pedido <i class="far fa-clock"></i></span>
                  <span class="title-responsive" v-else>{{ pedido.id != null ? pedido.id + (pedido.idPedido != null ? ' ('+ pedido.idPedido +')' : '') : pedido.idPedido != null ? pedido.idPedido : '-' }}</span>
               </div>
               <div class="col-6 wpx-xxl-125 px-1">
                  <span class="desc-responsive">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Data: </span>
                     {{ pedido.dataEmissao == null ? '-' : String(pedido.dataEmissao.dayOfMonth).padStart(2, '0') +'/'+ String(pedido.dataEmissao.monthValue).padStart(2, '0') +'/'+ pedido.dataEmissao.year }}
                  </span>
               </div>
               <div class="col-6 wpx-xxl-100 px-1">
                  <span class="desc-responsive"><span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Nº nota: </span>{{ pedido.numeroNota == null ? '-' : pedido.numeroNota }}</span>
               </div>
               <div class="col-6 wpx-xxl-135 px-1">
                  <span class="desc-responsive">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Emissão: </span>
                     {{ pedido.dataEmissaoNota == null ? '-' : String(pedido.dataEmissaoNota.dayOfMonth).padStart(2, '0') +'/'+ String(pedido.dataEmissaoNota.monthValue).padStart(2, '0') +'/'+ pedido.dataEmissaoNota.year }}
                  </span>
               </div>
               <div class="col px-1">
                  <span class="desc-responsive text-capitalize">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Status: </span>
                     <span :class="pedido.status == 'PEDIDO ENVIADO' ? 'text-green' : 'text-yellow'">{{ pedido.status == null ? '-' : String(pedido.status).toLowerCase() }}</span>
                  </span>
               </div>
               <div class="col-6 wpx-xxl-135 px-1">
                  <span class="desc-responsive">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Total: </span>
                     R$ {{ pedido.valor == null ? '-' : parseFloat(pedido.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}
                  </span>
               </div>
               <div class="col-6 wpx-xxl-100 px-1">
                  <span class="desc-responsive">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Origem: </span>
                     <strong v-if="pedido.origem == '0'" class="font-11">ERP</strong>
                     <img v-else-if="pedido.origem == '1'" src="@/assets/cliente/img/icon/origem1.jpg" class="rounded me-1" height="17" title="CCM" />
                     <img v-else-if="pedido.origem == '2'" src="@/assets/cliente/img/icon/origem2.jpg" class="rounded me-1" height="17" title="App" />
                     <span v-else>-</span>
                  </span>
               </div>
               <div class="col-6 wpx-xxl-100 px-1 mt-2 mt-xxl-0" :class="pedido.aprovacao == 'N' ? '' : 'd-none d-xxl-block'">
                  <button class="btn bg-success text-white font-13 me-1" @click="aprovar" v-if="pedido.aprovacao == 'N'">Aprovar</button>
                  <span class="text-success weight-500 font-13 me-3" v-else-if="pedido.aprovacao == 'S'"><i class="far fa-check font-12 me-1"></i>Aprovado</span>
                  <span class="font-13 me-3" v-else><i class="far fa-clock"></i></span>
               </div>
               <div class="wpx-xxl-125 px-1 text-xxl-end mt-2 mt-xxl-0">
                  <button class="btn bg-theme text-white font-13" @click="visualizar"><i class="far fa-clipboard font-11 me-1"></i> Visualizar</button>
               </div>
            </div>
         </div>
      </div>
   </li>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Pedido',
   props: ['pedido'],
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   methods: {
      visualizar : function () {
         this.$emit('visualizar', this.pedido)
      },
      aprovar : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'site/aprovarPedido',
            params: {
               id: this.pedido.idSequencial
            }
         }).then(() => {
            this.pedido.aprovacao = 'S';

            this.$toast.fire({
               icon: 'success',
               title: 'Pedido aprovado!'
            });
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      }
   }
}

</script>

<style scoped>

.card {
   margin-bottom: 4px;
   border: 1px solid #d6dce1;
   border-radius: 4px;
}

.card .card-body {
   padding: 8px 12px;
}

button {
   padding: 4px 12px !important;
}

</style>